import * as React from "react";
import { observer, Observer } from "mobx-react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonList,
  IonListHeader,
  IonMenu,
  IonRippleEffect,
  IonSearchbar,
  IonSpinner,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router";
import "./styles.css";
import { logo } from "../../config/constants";
import InveriteItem from "../InveriteItem";
import { BsImportFormat } from "../../lib/types/dataTypes";
import { refreshCircleOutline } from "ionicons/icons";
import FullScreenSpinner from "../FullScreenSpinner";
import { inverite } from "../../client/inverite";

interface SidebarProps extends RouteComponentProps {
  disabled: boolean;
  loading: boolean;
  loadingMore: boolean;
  loadingAll: boolean;
  verifications: BsImportFormat[];
  searchValue: string;
  onSearchChange: (event: any) => void;
  onRefresh: (event: any) => void;
  onGetAll: (event: any) => void;
  onGetMore: (event: any) => void;
}

const filterVerifications = (items: BsImportFormat[], match: string) => {
  const filter = (item: BsImportFormat) => {
    match = match && match.toString().trim();
    const regExp = new RegExp(match, "i");
    return (inverite.referenceToAppId(item.inveriteId) && (inverite.referenceToAppId(item.referenceId)).toString().match(regExp)) ||
      (item.clientName && item.clientName.match(regExp)) ||
      (item.inveriteId && item.inveriteId.match(regExp)) ||
      (item.appId && item.appId.toString().match(regExp)) ||
      (item.referenceId && item.referenceId.match(regExp));
  };
  return items.filter(filter);
};

const renderVerifications = (list: BsImportFormat[]) => {
  const groups: { date: string, items: BsImportFormat[] }[] = [];
  for (const item of list) {
    const date = (item.date || "").split(/\s/g)[0];
    const group = groups.find(g => g.date === date);
    if (!group) {
      groups.push({
        date,
        items: [item]
      });
    } else {
      group.items.push(item);
    }
  }
  return <Observer>{() => <>
    {groups.map(group => <div key={group.date}>
      <IonItemDivider color="light" className="textPrimary">
        {group.date}
      </IonItemDivider>
      {group.items.map(item => <InveriteItem key={item.inveriteId} item={item} />)}
    </div>)}
  </>}</Observer>;
};

const Sidebar: React.FC<SidebarProps> = observer(
  ({
     disabled,
     loading,
     loadingMore,
     loadingAll,
     history,
     verifications,
     searchValue,
     onSearchChange,
     onRefresh,
     onGetAll,
     onGetMore
   }) => (
    <IonMenu type="reveal" contentId="main" menuId="main" className="sidebar" disabled={disabled}>
      <IonHeader translucent>
        <IonToolbar color="primary" onClick={() => history.push("/")} className="ion-activatable relative">
          <IonThumbnail slot="start" className="ion-margin-start">
            <IonImg src={logo} />
          </IonThumbnail>
          <IonTitle className="ion-no-padding ion-margin-start textBold">
            Finjoy Bank Statement Data
          </IonTitle>
          <IonRippleEffect />
        </IonToolbar>
      </IonHeader>
      <IonSearchbar
        className="bar ion-no-padding"
        debounce={100}
        value={searchValue}
        onIonChange={onSearchChange}
        placeholder="App ID, External ID, Client Names..."
      />
      <IonContent>
        <IonList className="ion-no-padding">
          <IonListHeader className="flex ion-justify-content-between ion-padding-end">
            <IonText>
              Recent verifications
            </IonText>
            <IonButtons>
              <IonButton
                shape="round"
                onClick={onRefresh}
              >
                <IonIcon
                  slot="icon-only"
                  icon={refreshCircleOutline}
                  color="primary"
                />
              </IonButton>
            </IonButtons>
          </IonListHeader>
          {!loading && renderVerifications(searchValue ? filterVerifications(verifications, searchValue) : verifications)}
          {!loading && !searchValue && <IonItem button lines="full" onClick={onGetMore}>
            {loadingMore ? <IonSpinner color="secondary" /> : <IonText color="primary">Get more...</IonText>}
          </IonItem>}
          {!loading && searchValue && <IonItem button lines="full" onClick={onGetAll}>
            <IonText color="primary">{loadingAll ? (
              <div className="flex ion-align-items-center">
                <IonSpinner color="secondary" />
                <IonText className="ion-margin-start">Loading from server, this may take a while...</IonText>
              </div>
            ) : "Search more on server..."}</IonText>
          </IonItem>}
        </IonList>
        {loading && <FullScreenSpinner color="secondary" size={24} />}
      </IonContent>
    </IonMenu>
  ));

export default withRouter(Sidebar);
