import { useHistory } from "react-router";
import { BsImportFormat } from "../../lib/types/dataTypes";
import { inverite } from "../../client/inverite";
import React from "react";

const AppIdEntryHandler: React.FC<{ appId: number }> = ({ appId }) => {
  const history = useHistory();
  if (!appId) return null;
  (async () => {
    const { search } = window.location;
    const verification: BsImportFormat = await inverite.findByAppId(appId, true);
    if (verification) history.push(`/${verification.inveriteId}${search}`);
  })();
  return null;
};

export default AppIdEntryHandler
