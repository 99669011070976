import React from "react";
import { IonApp, IonRouterOutlet, IonSplitPane, setupConfig } from "@ionic/react";
import { observer } from "mobx-react";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./config/styles/ionic/variables.css";
import "./config/styles/global.css";
/* App Modules */
import { UI, ui } from "./client/ui";
import Home from "./pages/Home";
import FetchTool from "./pages/FetchTool";
import Verification from "./pages/Verification";
import { IonReactRouter } from "@ionic/react-router";
import Sidebar from "./components/Sidebar";
import { Route } from "react-router";
import { Api, api } from "./client/api";
import { Inverite, inverite } from "./client/inverite";
import { observable, reaction } from "mobx";
import { getQueryParameters } from "./utils/helpers";
import FullScreenSpinner from "./components/FullScreenSpinner";
import AppIdEntryHandler from "./components/AppIdEntryHandler";
import PopoverContainer from "./components/PopoverContainer";

// To keep platform styling consistency, set all styling mode to Material.
setupConfig({
  mode: "md"
});

@observer
class App extends React.Component {
  @observable appId: number;

  @observable sidebarLoading: boolean = false;
  @observable sidebarLoadingMore: boolean = false;
  @observable sidebarLoadingAll: boolean = false;

  ui: UI;
  api: Api;
  inverite: Inverite;

  constructor(props) {
    super(props);
    this.ui = ui;
    this.api = api;
    this.inverite = inverite;
    reaction(() => !!inverite.passwordEntered, (effect, reaction) => {
      reaction.dispose();
      return !this.appId && this.onRefresh(null);
    });
  };

  componentDidMount(): void {
    setTimeout(this.onLoad);
  }

  onLoad = async () => {
    const { search } = window.location;
    const inputs = getQueryParameters(search);
    const keys = Object.keys(inputs);
    for (const key of keys) {
      if (key === "hideSidebar") {
        ui.sidebarDisabled = true;
      }
      if (key === "appId") {
        this.appId = isNaN(Number(inputs[key]))
        ? null
        : Number(inputs[key])
      }
      if (key === "token") {
        inverite.verifyIframeToken(inputs[key]).catch(this.popupPassword);
      }
    }
    if (!keys.includes("token")) return this.popupPassword();
  }

  onRefresh = (event: any) => {
    this.sidebarLoading = true;
    inverite.searchValue = "";
    return inverite.loadAllData()
    .finally(() => this.sidebarLoading = false);
  }

  onGetAll = (event: any) => {
    this.sidebarLoadingAll = true;
    return inverite.getAllData()
    .finally(() => this.sidebarLoadingAll = false);
  }

  onGetMore = (event: any) => {
    this.sidebarLoadingMore = true;
    return inverite.getMoreData()
    .finally(() => this.sidebarLoadingMore = false);
  }

  popupPassword = () => {
    const handler = async data => {
      const { username, password } = data;
      const result = await inverite.verifyPassword(username, password);
      if (!result) return setTimeout(popup);
    };
    const popup = () => {
      return ui.alert({
        header: "Login",
        inputs: [
          {
            name: "username",
            type: "text",
            placeholder: "Username"
          },
          {
            name: "password",
            type: "password",
            placeholder: "Password"
          }
        ],
        backdropDismiss: false,
        buttons: [{
          text: "Confirm",
          handler
        }]
      })
      .then(alert => {
        const confirmButton = document.querySelectorAll("ion-alert button")[0];
        if (!confirmButton) return;
        const exec = async e => {
          if (e.key !== "Enter") return;
          window.removeEventListener("keypress", exec);
          (confirmButton as HTMLIonButtonElement).click();
          return alert.dismiss();
        };
        window.addEventListener("keypress", exec);
      });
    }
    return popup();
  }

  render() {
    return (
      <IonApp>
        <IonReactRouter>
          {!inverite.passwordEntered ? (
            <FullScreenSpinner size={40} color="primary" />
          ) : (
            <IonSplitPane contentId="main">
              <Sidebar
                disabled={ui.sidebarDisabled}
                loading={this.sidebarLoading}
                loadingMore={this.sidebarLoadingMore}
                loadingAll={this.sidebarLoadingAll}
                verifications={inverite.verifications}
                searchValue={inverite.searchValue}
                onSearchChange={inverite.handleSearchChange}
                onRefresh={this.onRefresh}
                onGetAll={this.onGetAll}
                onGetMore={this.onGetMore}
              />
              <IonRouterOutlet id="main">
                <Route exact path="/" component={Home} />
                <Route exact path="/:id" component={Verification} />
                <Route exact path="/fetch" component={FetchTool} />
              </IonRouterOutlet>
              <AppIdEntryHandler appId={this.appId} />
            </IonSplitPane>
          )}
        </IonReactRouter>
        {/* Global popover menu container */}
        <PopoverContainer ui={ui} />
      </IonApp>
    );
  }
}

export default App;
