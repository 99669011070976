import "mobx-react-lite/batchingForReactDom";
import React from "react";
import { observer, Observer } from "mobx-react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonRippleEffect,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { autorun, computed, observable, reaction } from "mobx";
import { StdErr } from "../../lib/types/miscTypes";
import { ui } from "../../client/ui";
import { BsImportFormat, BsImportFormatAccount, BsImportFormatAccountOffer, DbUser } from "../../lib/types/dataTypes";
import { inverite } from "../../client/inverite";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import { capitalize, getDisplayName, getPriceString, isEmpty } from "../../utils/helpers";
import "./styles.css";
import Accordion from "../../components/Accordion";
import { chevronDownSharp, chevronUpSharp } from "ionicons/icons";
import { AccordionController } from "../../lib/accordion";
import HeaderRefreshButton from "../../components/HeaderRefreshButton";
import { api } from "../../client/api";
import { endpointConfig } from "../../config/api";

const AccountSectionHeader = observer(({ isExpanded, title }) => (
  <div className="flex ion-justify-content-between ion-align-items-center ion-padding relative accountSectionHeader">
    <IonText color="primary" className="font-m">
      {title}
    </IonText>
    <IonIcon
      className="font-m"
      icon={isExpanded ? chevronUpSharp : chevronDownSharp}
      color="primary"
    />
    <IonRippleEffect />
  </div>
))

export interface VerificationProps extends RouteComponentProps<{
  id: string
}> {}

@observer
class Verification extends React.Component<VerificationProps> {
  accordionController: AccordionController;

  overviewRef = elm => this.overviewElm = elm;
  overviewElm: Accordion;

  calculationRef = elm => this.calculationElm = elm;
  calculationElm: Accordion;

  incomeRef = elm => this.incomeElm = elm;
  incomeElm: Accordion;

  periodRefs = {
    thirtyDays: elm => this.periodElms.thirtyDays = elm,
    threeMonths: elm => this.periodElms.threeMonths = elm,
    sixMonths: elm => this.periodElms.sixMonths = elm,
    annually: elm => this.periodElms.annually = elm,
  };
  periodElms: { [key: string]: Accordion } = {} as any;

  // additionalIncomeRef = elm => this.incomeElm = elm;
  // additionalIncomeElm: Accordion;

  otherLoansRef = elm => this.otherLoansElm = elm;
  otherLoansElm: Accordion;

  url: string;
  @observable id: string = "";
  @observable refreshing: boolean = false;
  @observable addressLoading: boolean = true;

  @observable application = {};
  @observable applicationStatusLoading: boolean = false;

  @observable applicationUser: DbUser = {} as DbUser;
  @observable applicationUserLoading: boolean = false;

  @observable alertAddressMismatch: boolean = false;

  @observable loanOfferLoading: boolean = false;
  @observable loanOffers: BsImportFormatAccountOffer[] = [];

  @computed get loading(): boolean {
    return isEmpty(this.data);
  };
  @computed get data(): BsImportFormat {
    const inveriteResult = inverite.verifications.find(item => item.inveriteId === this.id);
    return inveriteResult || {} as BsImportFormat;
  };
  @computed get accounts(): BsImportFormatAccount[] {
    return this.data.accounts || [];
  };
  @computed get selectedAcc(): string {
    return inverite.viewSelectedAccounts[this.id];
  };
  @computed get currentAccount(): BsImportFormatAccount {
    return this.accounts.find(acc => acc.accountNumber === this.selectedAcc) || {
      preliminary: {}
    } as BsImportFormatAccount;
  };
  @computed get currentAccountOffer(): BsImportFormatAccountOffer {
    return this.loanOffers.find(
      offer => offer.accountNumber === this.currentAccount.accountNumber
    ) || {} as BsImportFormatAccountOffer;
  };
  @computed get flags() {
    return inverite.getAccountFlags(this.currentAccount);
  };

  @computed get accountsWithFlags(): BsImportFormatAccount[] {
    return this.accounts.filter(acc => {
      const flags = inverite.getAccountFlags(acc);
      return flags.returnedTx || flags.bankruptcyTx || flags.collectionTx || flags.gamblingTx
    });
  };
  @computed get otherAccountsWithFlags(): BsImportFormatAccount[] {
    return this.accountsWithFlags.filter(acc => acc.accountNumber !== this.selectedAcc);
  };
  @computed get accountsNeedBacker(): BsImportFormatAccount[] {
    return this.accounts.filter(acc => {
      const offer = this.loanOffers.find(offer => offer.accountNumber === acc.accountNumber);
      if (!offer) return false;
      return (offer.preliminary || {}).status === "backer";
    });
  };
  @computed get otherAccountsNeedBacker(): BsImportFormatAccount[] {
    return this.accountsNeedBacker.filter(acc => acc.accountNumber !== this.selectedAcc);
  };
  @computed get accountsReject(): BsImportFormatAccount[] {
    return this.accounts.filter(acc => {
      const offer = this.loanOffers.find(offer => offer.accountNumber === acc.accountNumber);
      if (!offer) return false;
      return (offer.preliminary || {}).status === "reject";
    });
  };
  @computed get otherAccountsReject(): BsImportFormatAccount[] {
    return this.accountsReject.filter(acc => acc.accountNumber !== this.selectedAcc);
  };
  @computed get accountsHasIncome(): BsImportFormatAccount[] {
    return this.accounts.filter(acc => {
      const offer = this.loanOffers.find(offer => offer.accountNumber === acc.accountNumber);
      if (!offer) return false;
      return !!offer.avgMonthlyIncome90;
    });
  };
  @computed get otherAccountsHasIncome(): BsImportFormatAccount[] {
    return this.accountsHasIncome.filter(acc => acc.accountNumber !== this.selectedAcc);
  };

  @computed get inveriteExternalLink(): string {
    return `https://www.inverite.com/merchant/request/view/${this.id}`;
  };

  @computed get alertNameMismatch(): boolean {
    if (isEmpty(this.applicationUser) || isEmpty(this.data)) return true;
    const displayName = getDisplayName(this.applicationUser);
    const clientName = this.data.clientName || "";
    if (!displayName || !clientName) return true;
    const displayNameRegex = new RegExp(displayName.replace(" ", "|"), "ig");
    const clientNameRegex = new RegExp(clientName.replace(" ", "|"), "ig");
    return !displayName.match(clientNameRegex) && !clientName.match(displayNameRegex);
  };

  @computed get applicationCompleted(): boolean {
    return this.application && !!this.application["user_flow_finished_date"];
  };

  constructor (props) {
    super(props);
    this.accordionController = new AccordionController("Verification", false);
    this.url = props.match.url;
    autorun(() =>
      this.id &&
      !isEmpty(this.accounts) &&
      (isEmpty(this.selectedAcc) || isEmpty(this.currentAccount)) &&
      (inverite.viewSelectedAccounts[this.id] = this.accounts[0].accountNumber)
    );
    autorun(this.checkAlertAddressMismatch);
    reaction(() => this.data && this.data.appId, this.getApplication);
    reaction(() => this.data && this.data.appId, this.getApplicationUser);
    reaction(() => this.data && this.data.clientName, () => (
      document.getElementsByTagName("title")[0].innerHTML = `${this.data.clientName || this.id} | Finjoy Bank Statement Data`
    ));
    reaction(() => this.data, this.getLoanOffers);
  }

  ionViewWillEnter = () => {
    const { id } = this.props.match.params;
    this.id = id;
    if (isEmpty(this.data)) return this.recursivelyGetMore();
  };

  showError = (err: StdErr) => ui.showError({ err, actionName: this.id });

  recursivelyGetMore = async () => isEmpty(this.data) ? inverite.getMoreData().then(this.recursivelyGetMore) : Promise.resolve();

  onRefresh = (event: any) => {
    this.refreshing = true;
    return inverite.refreshVerification(this.id)
    .then(this.getApplicationUser)
    .catch(this.showError)
    .finally(() => this.refreshing = false);
  };

  checkAlertAddressMismatch = async () => {
    if (isEmpty(this.applicationUser) || isEmpty(this.data)) return true;
    this.addressLoading = true;
    let clientAddress = this.data.clientAddress;
    let full_address = this.applicationUser.full_address;

    const getClientAddressId = () => api.GET(endpointConfig.google_places_search(clientAddress))
    .then(response => {
      const { candidates } = response.data;
      const { place_id } = (candidates || [])[0] || {};
      return place_id;
    });

    const getFullAddressId = () => api.GET(endpointConfig.google_places_search(full_address))
    .then(response => {
      const { candidates } = response.data;
      const { place_id } = (candidates || [])[0] || {};
      return place_id;
    });

    const [clientAddressId, fullAddressId] = await Promise.all([
      getClientAddressId(), getFullAddressId()
    ]).catch(console.error);

    if (clientAddressId !== fullAddressId) {
      const getClientAddressCoords = () => api.GET(endpointConfig.google_geocoding_search(clientAddress))
      .then(response => response.data.results);
      const getFullAddressCoords = () => api.GET(endpointConfig.google_geocoding_search(full_address))
      .then(response => response.data.results);
      const [clientAddressCoords, fullAddressCoords] = await Promise.all([
        getClientAddressCoords(), getFullAddressCoords()
      ]).catch(console.error);

      this.alertAddressMismatch = !(clientAddressCoords || []).some(coord1 => {
        const { geometry } = coord1 || {};
        const { location } = geometry || {};
        const lat1 = (location || {}).lat;
        const lng1 = (location || {}).lng;
        return (fullAddressCoords || []).some(coord2 => {
          const { geometry } = coord2 || {};
          const { location } = geometry || {};
          const lat2 = (location || {}).lat;
          const lng2 = (location || {}).lng;
          return lat1 === lat2 && lng1 === lng2;
        });
      });
    } else {
      this.alertAddressMismatch = false;
    }
    // await new Promise(resolve => setTimeout(resolve, 200));
    return this.addressLoading = false;
  };

  getApplication = () => {
    if (isEmpty(this.data)) return;
    this.applicationStatusLoading = true;
    return inverite.getLoanApplicationByInveriteId(this.data.inveriteId)
    .then(app => this.application = app)
    .catch(this.showError)
    .finally(() => this.applicationStatusLoading = false);
  }

  getApplicationUser = async () => {
    if (isEmpty(this.data)) return;
    this.applicationUserLoading = true;
    return inverite.getLoanApplicationUserByAppId(Number(this.data.appId))
    .then(user => this.applicationUser = user)
    .catch(this.showError)
    .finally(() => this.applicationUserLoading = false);
  };

  getLoanOffers = async () => {
    if (isEmpty(this.data)) return;
    this.loanOfferLoading = true;
    return inverite.getLoanOffersByInveriteId(this.id)
    .then(result => this.loanOffers = result)
    .catch(this.showError)
    .finally(() => this.loanOfferLoading = false);
  };

  handleAccountPopover = (event: any) =>
    ui.popover({
      event,
      component: () => (
        <IonList>
          {this.accounts.map(acc => (
            <IonItem
              lines="full"
              button key={acc.accountNumber}
              onClick={() => this.handleAccountClick(acc.accountNumber)}
            >
              <div className="flex column ion-justify-content-center" style={{ minHeight: "75px" }}>
                <IonText>{capitalize(acc.type || "unknown type")}:&nbsp;{acc.accountNumber}</IonText>
                <div className="flex ion-align-items-center">
                  {this.accountsHasIncome.includes(acc) && <IonChip className="font-xxs" color="primary">Income</IonChip>}
                  {this.accountsWithFlags.includes(acc) && <IonChip className="font-xxs" color="danger">Flags</IonChip>}
                  {this.accountsNeedBacker.includes(acc) && <IonChip className="font-xxs" color="warning">Need Backer</IonChip>}
                  {this.accountsReject.includes(acc) && <IonChip className="font-xxs" color="danger">Reject</IonChip>}
                  {!this.accountsNeedBacker.includes(acc) && !this.accountsReject.includes(acc) && (
                    <IonChip className="font-xxs" color="medium">Normal</IonChip>
                  )}
                </div>
              </div>
            </IonItem>
          ))}
        </IonList>
      )
    });

  handleAccountClick = (accountNumber: string) => {
    inverite.viewSelectedAccounts[this.id] = accountNumber;
    return ui.dismissPopover();
  };

  handleAccordionClick = (event: any, section: string) => {
    const isExpanded = this.accordionController.isAccordionExpanded(section);
    return this.accordionController.onAccordionFold(section, !isExpanded);
  };

  renderAccountPeriodSection = (section: string, title: string) => <Observer>{() => {
    const sectionData = this.currentAccount[section] || {};
    return <Accordion
      ref={this.periodRefs[section]}
      duration={200}
      className={`ion-padding accountSection ${section}`}
      backgroundColor="#ffffff00"
      headerComponent={
        <AccountSectionHeader
          title={`${title} (${sectionData.statStartDate} - ${sectionData.statEndDate})`}
          isExpanded={this.accordionController.isAccordionExpanded(section)}
        />
      }
      expanded={this.accordionController.isAccordionExpanded(section)}
      onClick={e => this.handleAccordionClick(e, section)}
    >
      <IonCard>
        <IonCardContent>
          <IonList className="ion-no-padding">
            {/*<div className="flex half ion-align-items-end">*/}
            {/*  <IonItem lines="full">*/}
            {/*    <IonLabel position="stacked" className="textPrimary">*/}
            {/*      Number of credits*/}
            {/*    </IonLabel>*/}
            {/*    <IonText>*/}
            {/*      {sectionData.numberCredits || "-"}*/}
            {/*    </IonText>*/}
            {/*  </IonItem>*/}
            {/*  <IonItem lines="full">*/}
            {/*    <IonLabel position="stacked" className="textPrimary">*/}
            {/*      Total amount of credits*/}
            {/*    </IonLabel>*/}
            {/*    <IonText>*/}
            {/*      ${sectionData.totalCreditAmount || "-"}*/}
            {/*    </IonText>*/}
            {/*  </IonItem>*/}
            {/*</div>*/}
            {/*<div className="flex half ion-align-items-end">*/}
            {/*  <IonItem lines="full">*/}
            {/*    <IonLabel position="stacked" className="textPrimary">*/}
            {/*      Number of debits*/}
            {/*    </IonLabel>*/}
            {/*    <IonText>*/}
            {/*      {sectionData.numberDebits || "-"}*/}
            {/*    </IonText>*/}
            {/*  </IonItem>*/}
            {/*  <IonItem lines="full">*/}
            {/*    <IonLabel position="stacked" className="textPrimary">*/}
            {/*      Total amount of debits*/}
            {/*    </IonLabel>*/}
            {/*    <IonText>*/}
            {/*      ${sectionData.totalDebitAmount || "-"}*/}
            {/*    </IonText>*/}
            {/*  </IonItem>*/}
            {/*</div>*/}
            <div className="flex half ion-align-items-end">
              <IonItem lines="full">
                <IonLabel position="stacked" className="textPrimary">
                  Number of other ongoing loans
                </IonLabel>
                <IonText>
                  {sectionData.numberOtherLoans || "-"}
                </IonText>
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="stacked" className="textPrimary">
                  Average monthly loan payment
                </IonLabel>
                <IonText>
                  {getPriceString(sectionData.averageMonthlyLoanDebitAmount) || "-"}
                </IonText>
              </IonItem>
            </div>
            <div className="flex half ion-align-items-end">
              <IonItem lines="full">
                <IonLabel position="stacked" className="textPrimary">
                  Total amount of other loan debits
                </IonLabel>
                <IonText>
                  {getPriceString(sectionData.totalOtherLoanDebitAmount) || "-"}
                </IonText>
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="stacked" className="textPrimary">
                  Total amount of other loan credits
                </IonLabel>
                <IonText>
                  {getPriceString(sectionData.totalOtherLoanCreditAmount) || "-"}
                </IonText>
              </IonItem>
            </div>
            <div className="flex half ion-align-items-end">
              <IonItem lines="full">
                <IonLabel position="stacked" className="textPrimary">
                  Total period income amount
                </IonLabel>
                <IonText>
                  {getPriceString(sectionData.totalIncomeAmountWithoutGovernment) || "-"}
                </IonText>
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="stacked" className="textPrimary">
                  Total including government payments
                </IonLabel>
                <IonText>
                  {getPriceString(sectionData.totalIncomeAmount) || "-"}
                </IonText>
              </IonItem>
            </div>
            <div className="flex half ion-align-items-end">
              <IonItem lines="full">
                <IonLabel position="stacked" className="textPrimary">
                  Government payment amount
                </IonLabel>
                <IonText>
                  {getPriceString(sectionData.totalIncomeAmount - sectionData.totalIncomeAmountWithoutGovernment) || "-"}
                </IonText>
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="stacked" className="textPrimary">
                  Total gambling debits
                </IonLabel>
                <IonText className={sectionData.totalGamblingDebit === 0 ? "" : "textDanger"}>
                  {getPriceString(sectionData.totalGamblingDebit) || "-"}
                </IonText>
              </IonItem>
            </div>
            <div className="flex half ion-align-items-end">
              <IonItem lines="full">
                <IonLabel position="stacked" className="textPrimary">
                  DSR
                </IonLabel>
                <IonText>
                  {sectionData.dsr || "-"}
                </IonText>
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="stacked" className="textPrimary">
                  NSF (Returned transactions)
                </IonLabel>
                <IonText className={isEmpty(sectionData.returnedTransactions) ? "" : "textDanger"}>
                  {(sectionData.returnedTransactions || []).length || "-"}
                </IonText>
              </IonItem>
            </div>
            {this.renderPeriodIncomeTable(
              sectionData.incomes,
              e => this.periodElms[section] && this.periodElms[section].ensureHeight()
            )}
            {this.renderPeriodOtherLoanDebitTable(
              sectionData.otherLoanDebits,
              e => this.periodElms[section] && this.periodElms[section].ensureHeight()
            )}
            {this.renderPeriodOtherLoanCreditTable(
              sectionData.otherLoanCredits,
              e => this.periodElms[section] && this.periodElms[section].ensureHeight()
            )}
            {this.renderPeriodReturnedTransactionTable(
              sectionData.returnedTransactions,
              e => this.periodElms[section] && this.periodElms[section].ensureHeight()
            )}
            {this.renderPeriodGamblingTransactionTable(
              sectionData.gamblingTransactions,
              e => this.periodElms[section] && this.periodElms[section].ensureHeight()
            )}
          </IonList>
        </IonCardContent>
      </IonCard>
    </Accordion>;
  }}</Observer>;

  renderPeriodIncomeTable = (incomes, onClick) => {
    const Table = <div className="flex column transactionTable ion-padding-vertical">
      <div className="flex transactionTableRow">
        <IonText className="textPrimary textBold">Category</IonText>
        <IonText className="textPrimary textBold">Description</IonText>
        <IonText className="textPrimary textBold">Total amount</IonText>
        <IonText className="textPrimary textBold">Transactions</IonText>
      </div>
      <Observer>{() => !isEmpty(incomes) ? incomes.map((income, i) => (
        <div className="flex transactionTableRow textDark" key={i}>
          <IonText>{income.category}</IonText>
          <IonText>{income.description}</IonText>
          <IonText>{getPriceString(income.totalAmount)}</IonText>
          <IonText>{income.transactions}</IonText>
        </div>
      )) : <IonText className="ion-margin-top ion-text-center">No income detail</IonText>}</Observer>
    </div>;
    return <Accordion
      duration={200}
      className="ion-padding accountSection"
      backgroundColor="#ffffff00"
      color="light"
      title="Income details"
      onClick={onClick}
    >
      {Table}
    </Accordion>;
  }

  renderPeriodOtherLoanDebitTable = (otherLoans, onClick) => {
    const Table = <div className="flex column transactionTable ion-padding-vertical">
      <div className="flex transactionTableRow">
        <IonText className="textPrimary textBold">Category</IonText>
        <IonText className="textPrimary textBold">Description</IonText>
        <IonText className="textPrimary textBold">Total amount</IonText>
        <IonText className="textPrimary textBold">Transactions</IonText>
      </div>
      <Observer>{() => !isEmpty(otherLoans) ? otherLoans.map((otherLoan, i) => (
        <div className="flex transactionTableRow textDark" key={i}>
          <IonText>{otherLoan.category}</IonText>
          <IonText>{otherLoan.description}</IonText>
          <IonText>{getPriceString(otherLoan.totalAmount)}</IonText>
          <IonText>{otherLoan.transactions}</IonText>
        </div>
      )) : <IonText className="ion-margin-top ion-text-center">No other loan debits</IonText>}</Observer>
    </div>;
    return <Accordion
      duration={200}
      className="ion-padding accountSection"
      backgroundColor="#ffffff00"
      color="light"
      title="Other loan debits"
      onClick={onClick}
    >
      {Table}
    </Accordion>;
  }

  renderPeriodOtherLoanCreditTable = (otherLoans, onClick) => {
    const Table = <div className="flex column transactionTable ion-padding-vertical">
      <div className="flex transactionTableRow">
        <IonText className="textPrimary textBold">Category</IonText>
        <IonText className="textPrimary textBold">Description</IonText>
        <IonText className="textPrimary textBold">Total amount</IonText>
        <IonText className="textPrimary textBold">Transactions</IonText>
      </div>
      <Observer>{() => !isEmpty(otherLoans) ? otherLoans.map((otherLoan, i) => (
        <div className="flex transactionTableRow textDark" key={i}>
          <IonText>{otherLoan.category}</IonText>
          <IonText>{otherLoan.description}</IonText>
          <IonText>{getPriceString(otherLoan.totalAmount)}</IonText>
          <IonText>{otherLoan.transactions}</IonText>
        </div>
      )) : <IonText className="ion-margin-top ion-text-center">No other loan credits</IonText>}</Observer>
    </div>;
    return <Accordion
      duration={200}
      className="ion-padding accountSection"
      backgroundColor="#ffffff00"
      color="light"
      title="Other loan credits"
      onClick={onClick}
    >
      {Table}
    </Accordion>;
  }

  renderPeriodReturnedTransactionTable = (transactions, onClick) => {
    const Table = this.renderTransactionTable(transactions, null, true, true);
    return <Accordion
      duration={200}
      className="ion-padding accountSection"
      backgroundColor="#ffffff00"
      color="light"
      title="Returned transactions"
      onClick={onClick}
    >
      {Table}
    </Accordion>;
  }

  renderPeriodGamblingTransactionTable = (transactions, onClick) => {
    const Table = this.renderTransactionTable(transactions, null, true, true);
    return <Accordion
      duration={200}
      className="ion-padding accountSection"
      backgroundColor="#ffffff00"
      color="light"
      title="Gambling transactions"
      onClick={onClick}
    >
      {Table}
    </Accordion>;
  }

  renderTransactionTable = (transactions, clickRef, includeDetails?, noTitle?) => {
    const Table = <div className="flex column transactionTable ion-padding-vertical">
      <div className={`flex transactionTableRow ${includeDetails ? "withDetails" : ""}`}>
        <IonText className="textPrimary textBold">Date</IonText>
        {includeDetails && <IonText className="textPrimary textBold">Details</IonText>}
        <IonText className="textPrimary textBold">Category</IonText>
        <IonText className="textPrimary textBold">Balance</IonText>
        <IonText className="textPrimary textBold">Credit</IonText>
        <IonText className="textPrimary textBold">Debit</IonText>
        <IonText className="textPrimary textBold">Flags</IonText>
      </div>
      <Observer>{() => !isEmpty(transactions) ? transactions.map((transaction, i) => (
        <div className={`flex transactionTableRow textDark ${includeDetails ? "withDetails" : ""}`} key={i}>
          <IonText>{transaction.date}</IonText>
          {includeDetails && <IonText>{transaction.details}</IonText>}
          <IonText>{transaction.category}</IonText>
          <IonText>{getPriceString(transaction.balance)}</IonText>
          <IonText>{getPriceString(transaction.credit)}</IonText>
          <IonText>{getPriceString(transaction.debit)}</IonText>
          <IonText>{transaction.flags && transaction.flags.join(", ")}</IonText>
        </div>
      )) : <IonText className="ion-margin-top ion-text-center">No transactions</IonText>}</Observer>
    </div>;
    if (noTitle) return Table;
    return <Accordion
      duration={200}
      className="ion-padding accountSection otherLoans"
      backgroundColor="#ffffff00"
      color="light"
      title="Transaction details"
      onClick={clickRef}
    >
      {Table}
    </Accordion>;
  }

  render() {
    return <IonPage className="verification">
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="textSelectable">
            {this.data.clientName || this.id || ""}
          </IonTitle>
          <IonButtons slot="end">
            <HeaderRefreshButton
              loading={this.refreshing}
              spinnerColor="secondary"
              onRefresh={this.onRefresh}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="textSelectable">
        {this.loading ? <FullScreenSpinner color="secondary" size={40} /> : (
          <div className="flex max column container ion-padding">
            <div className="flex ion-justify-content-between ion-wrap summary">
              <div className="flex column">
                <IonText className="textBold">
                  Applicant:&nbsp;{this.data.clientName || <IonText className="textItalic" color="medium">Unknown client name</IonText>}
                </IonText>
                <IonText>
                  App ID:&nbsp;{this.data.appId}
                </IonText>
                <IonText>
                  Application Status:&nbsp;
                  <IonText color={this.applicationCompleted ? "success" : "warning"}>{
                    this.applicationStatusLoading
                      ? <IonSpinner color="primary" style={{ width: "15px", height: "15px" }} />
                      : (this.applicationCompleted ? "Completed" : "Incomplete")}
                  </IonText>
                </IonText>
                <IonText>
                  Submission date:&nbsp;{this.data.date}
                </IonText>
                <IonText>
                  Address:&nbsp;{this.data.clientAddress}
                </IonText>
              </div>
              <div className="flex column">
                <IonText>
                  Status:&nbsp;{this.data.submissionStatus}
                </IonText>
                <IonText>
                  External ID:&nbsp;{this.data.inveriteId}
                </IonText>
                <IonText>
                  Number of accounts:&nbsp;{this.accounts.length}
                </IonText>
                {/*<IonText color="primary" className="flex ion-justify-content-start">*/}
                {/*  <a href={this.inveriteExternalLink} target="_blank" rel="noopener noreferrer" className="flex ion-align-items-center textNoUnderline">*/}
                {/*    <span className="textUnderline">Open in External Dashboard</span>&nbsp;<IonIcon icon={openOutline} color="primary" />*/}
                {/*  </a>*/}
                {/*</IonText>*/}
              </div>
            </div>

            <div className="flex column ion-justify-content-center ion-align-items-center ion-margin-top accountSelector">
              <div className="flex ion-align-items-center ion-justify-content-center">
                <IonButton onClick={this.handleAccountPopover} fill="clear" className="font-s textNoTransform">
                  <span className="textDark">{this.accounts.length > 1 ? "Accounts" : "Account"} ({this.accounts.length}):&nbsp;</span>
                  {capitalize(this.currentAccount.type || "unknown type")}:&nbsp;{this.currentAccount.accountNumber}
                  {this.loanOfferLoading
                    ? <>&nbsp;&nbsp;<IonSpinner color="primary" style={{ width: "18px", height: "18px" }} /></>
                    : <IonIcon slot="end" icon={chevronDownSharp} />}
                </IonButton>
              </div>
              {!isEmpty(this.otherAccountsHasIncome) && (
                <IonText color="primary">
                  {this.otherAccountsHasIncome.length} other {this.otherAccountsReject.length > 1 ? "accounts" : "account"} has income.
                </IonText>
              )}
              {!isEmpty(this.otherAccountsWithFlags) && (
                <IonText color="danger">
                  {this.otherAccountsWithFlags.length} other {this.otherAccountsWithFlags.length > 1 ? "accounts" : "account"} have flags.
                </IonText>
              )}
              {!isEmpty(this.otherAccountsNeedBacker) && (
                <IonText color="warning">
                  {this.otherAccountsNeedBacker.length} other {this.otherAccountsNeedBacker.length > 1 ? "accounts" : "account"} {this.otherAccountsNeedBacker.length > 1 ? "require" : "requires"} backer.
                </IonText>
              )}
              {!isEmpty(this.otherAccountsReject) && (
                <IonText color="danger">
                  {this.otherAccountsReject.length} other {this.otherAccountsReject.length > 1 ? "accounts" : "account"} pre-approval rejected.
                </IonText>
              )}
            </div>

            <div className="flex column ion-align-items-center ion-margin-top accountSections">
              <Accordion
                ref={this.overviewRef}
                duration={200}
                className="ion-padding accountSection overview"
                backgroundColor="#ffffff00"
                headerComponent={
                  <AccountSectionHeader
                    title="Overview"
                    isExpanded={!this.accordionController.isAccordionExpanded("overview")}
                  />
                }
                expanded={!this.accordionController.isAccordionExpanded("overview")}
                onClick={e => this.handleAccordionClick(e, "overview")}
              >
                <div className="flex column">
                  <IonCard>
                    <IonCardContent>
                      <IonList className="ion-no-padding">
                        <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                          Account information
                        </IonListHeader>
                        <div className="flex half ion-align-items-end">
                          <IonItem lines="full">
                            <IonLabel position="stacked" className="textPrimary">
                              Bank name
                            </IonLabel>
                            <IonText>
                              {this.currentAccount.bankName || "-"}
                            </IonText>
                          </IonItem>
                          <IonItem lines="full">
                            <IonLabel position="stacked" className="textPrimary">
                              Account type
                            </IonLabel>
                            <IonText>
                              {this.currentAccount.type || "-"}
                            </IonText>
                          </IonItem>
                        </div>
                        <div className="flex half ion-align-items-end">
                          <IonItem lines="full">
                            <IonLabel position="stacked" className="textPrimary">
                              Institution
                            </IonLabel>
                            <IonText>
                              {this.currentAccount.institution || "-"}
                            </IonText>
                          </IonItem>
                          <IonItem lines="full">
                            <IonLabel position="stacked" className="textPrimary">
                              Transit number
                            </IonLabel>
                            <IonText>
                              {this.currentAccount.transitNumber || "-"}
                            </IonText>
                          </IonItem>
                          <IonItem lines="full">
                            <IonLabel position="stacked" className="textPrimary">
                              Account number
                            </IonLabel>
                            <IonText>
                              {this.currentAccount.accountNumber || "-"}
                            </IonText>
                          </IonItem>
                        </div>
                        {/*<div className="flex half ion-align-items-end">*/}
                        {/*  <IonItem lines="full">*/}
                        {/*    <IonLabel position="stacked" className="textPrimary">*/}
                        {/*      Sum of 3 highest deposits:*/}
                        {/*    </IonLabel>*/}
                        {/*    <IonText>*/}
                        {/*      {this.currentAccount.sum3HighestDeposits ? getPriceString(this.currentAccount.sum3HighestDeposits) : "-"}*/}
                        {/*    </IonText>*/}
                        {/*  </IonItem>*/}
                        {/*  <IonItem lines="full">*/}
                        {/*    <IonLabel position="stacked" className="textPrimary">*/}
                        {/*      Sum of 3 highest withdrawals:*/}
                        {/*    </IonLabel>*/}
                        {/*    <IonText>*/}
                        {/*      {this.currentAccount.sum3HighestWithdrawals ? getPriceString(this.currentAccount.sum3HighestWithdrawals) : "-"}*/}
                        {/*    </IonText>*/}
                        {/*  </IonItem>*/}
                        {/*</div>*/}
                      </IonList>

                      <IonList className="ion-no-padding">
                        <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                          Loan Offer
                        </IonListHeader>
                        {this.loanOfferLoading ? (
                          <div className="flex ion-align-items-center ion-justify-content-center">
                            <IonSpinner color="primary" />
                          </div>
                        ) : (
                          <div className="flex half ion-align-items-end">
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textPrimary">
                                Max monthly payment
                              </IonLabel>
                              <IonText>
                                {getPriceString(this.currentAccountOffer.maxMonthlyPmnt) || "-"}
                              </IonText>
                            </IonItem>
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textPrimary">
                                Offered loan amount ({this.currentAccountOffer.maxLoanTermMonth} months term)
                              </IonLabel>
                              <IonText>
                                {getPriceString(this.currentAccountOffer.offeredLoanAmount) || "-"}
                              </IonText>
                            </IonItem>
                          </div>
                        )}
                      </IonList>

                      <IonList className="ion-no-padding">
                        <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                          Pre-approval
                        </IonListHeader>
                        <IonItem lines="full">
                          <IonLabel position="stacked" className="textPrimary">
                            Recommendation
                          </IonLabel>
                          <IonText>
                            {this.currentAccount.preliminary.status === "normal"
                              ? "Normal"
                              : this.currentAccount.preliminary.status === "backer"
                                ? "Need backer"
                                : this.currentAccount.preliminary.status === "reject"
                                  ? "Reject"
                                  : "Unknown"}
                          </IonText>
                        </IonItem>
                        {this.currentAccount.preliminary.reason && (
                          <IonItem lines="full">
                            <IonLabel position="floating" className="textPrimary">
                              Reason(s)
                            </IonLabel>
                            <IonTextarea
                              autoGrow
                              readonly
                              value={this.currentAccount.preliminary.reason || "-"}
                            />
                          </IonItem>
                        )}
                      </IonList>

                      {(this.alertNameMismatch || this.alertAddressMismatch) && (
                        <IonList className="ion-no-padding">
                          <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                            Alerts
                          </IonListHeader>
                          {this.applicationUserLoading ? (
                            <div className="flex ion-align-items-center ion-justify-content-center">
                              <IonSpinner color="primary" />
                            </div>
                          ) : <>
                            {this.alertNameMismatch && (
                              <div className="flex half ion-align-items-end">
                                <IonItem lines="full">
                                  <IonLabel position="stacked" className="textPrimary">
                                    Name mismatch
                                  </IonLabel>
                                  <IonText color={this.alertNameMismatch ? "danger" : undefined}>
                                    {this.alertNameMismatch ? "Yes" : "No"}
                                  </IonText>
                                </IonItem>
                                <IonItem lines="full">
                                  <IonLabel position="stacked" className="textPrimary">
                                    Name on application
                                  </IonLabel>
                                  <IonText>
                                    {getDisplayName(this.applicationUser)}
                                  </IonText>
                                </IonItem>
                              </div>
                            )}
                            {this.alertAddressMismatch && (
                              <div className="flex half ion-align-items-end">
                                <IonItem lines="full">
                                  <IonLabel position="stacked" className="textPrimary">
                                    Address mismatch
                                  </IonLabel>
                                  <IonText color={(!this.addressLoading && this.alertAddressMismatch) ? "danger" : undefined}>
                                    {this.addressLoading ? "..." : this.alertAddressMismatch ? "Yes" : "No"}
                                  </IonText>
                                </IonItem>
                                <IonItem lines="full">
                                  <IonLabel position="stacked" className="textPrimary">
                                    Address on application
                                  </IonLabel>
                                  <IonText>
                                    {this.applicationUser.full_address}
                                  </IonText>
                                </IonItem>
                              </div>
                            )}
                          </>}
                        </IonList>
                      )}

                      {!isEmpty(Object.values(this.flags).filter(Boolean)) && (
                        <IonList className="ion-no-padding">
                          <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                            Flags
                          </IonListHeader>
                          {(this.flags.bankruptcyTx || this.flags.returnedTx) && (
                            <div className="flex half ion-align-items-end">
                              {this.flags.bankruptcyTx && (
                                <IonItem lines="full">
                                  <IonLabel position="stacked" className="textPrimary">
                                    Has bankruptcy transaction
                                  </IonLabel>
                                  <IonText color={this.flags.bankruptcyTx ? "danger" : undefined}>
                                    {this.flags.bankruptcyTx ? "Yes" : "No"}
                                  </IonText>
                                </IonItem>
                              )}
                              {this.flags.returnedTx && (
                                <IonItem lines="full">
                                  <IonLabel position="stacked" className="textPrimary">
                                    Has returned transaction
                                  </IonLabel>
                                  <IonText color={this.flags.returnedTx ? "danger" : undefined}>
                                    {this.flags.returnedTx ? "Yes" : "No"}
                                  </IonText>
                                </IonItem>
                              )}
                            </div>
                          )}
                          {(this.flags.gamblingTx || this.flags.collectionTx) && (
                            <div className="flex half ion-align-items-end">
                              {this.flags.gamblingTx && (
                                <IonItem lines="full">
                                  <IonLabel position="stacked" className="textPrimary">
                                    Has gambling transaction
                                  </IonLabel>
                                  <IonText color={this.flags.gamblingTx ? "danger" : undefined}>
                                    {this.flags.gamblingTx ? "Yes" : "No"}
                                  </IonText>
                                </IonItem>
                              )}
                              {this.flags.collectionTx && (
                                <IonItem lines="full">
                                  <IonLabel position="stacked" className="textPrimary">
                                    Has collection transaction
                                  </IonLabel>
                                  <IonText color={this.flags.collectionTx ? "danger" : undefined}>
                                    {this.flags.collectionTx ? "Yes" : "No"}
                                  </IonText>
                                </IonItem>
                              )}
                            </div>
                          )}
                          {this.flags.finjoyTx && (
                            <div className="flex half ion-align-items-end">
                              <IonItem lines="full">
                                <IonLabel position="stacked" className="textPrimary">
                                  Has <IonText color="secondary" className="textBold">Finjoy</IonText> transaction
                                </IonLabel>
                                <IonText>
                                  {this.flags.finjoyTx ? "Yes" : "No"}
                                </IonText>
                              </IonItem>
                            </div>
                          )}
                        </IonList>
                      )}
                    </IonCardContent>
                  </IonCard>

                  {/*<IonCard>*/}
                  {/*  <IonCardContent className="bankStatementImport">*/}
                  {/*    */}
                  {/*  </IonCardContent>*/}
                  {/*</IonCard>*/}
                </div>
              </Accordion>

              <Accordion
                ref={this.calculationRef}
                duration={200}
                className="ion-padding accountSection calculation"
                backgroundColor="#ffffff00"
                headerComponent={
                  <AccountSectionHeader
                    title="Loan Offer Calculation Details"
                    isExpanded={!this.accordionController.isAccordionExpanded("calculation")}
                  />
                }
                expanded={!this.accordionController.isAccordionExpanded("calculation")}
                onClick={e => this.handleAccordionClick(e, "calculation")}
              >
                <div className="flex column">
                  <IonCard>
                    <IonCardContent>
                      <IonList className="ion-no-padding" style={{ width: "100%" }}>
                        {this.loanOfferLoading ? (
                          <div className="flex ion-align-items-center ion-justify-content-center">
                            <IonSpinner color="primary" />
                          </div>
                        ) : <>
                          <IonItemDivider className="dividerNoBorder">Other Loans</IonItemDivider>
                          <div className="flex half ion-align-items-end">
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textPrimary">
                                Average monthly loan payment over 90 days
                              </IonLabel>
                              <IonText>
                                {getPriceString(this.currentAccountOffer.loanPayment90) || "-"}
                              </IonText>
                            </IonItem>
                          </div>
                          <IonItemDivider className="dividerNoBorder">90 Days Average Monthly Income</IonItemDivider>
                          <div className="flex half ion-align-items-end">
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textSuccess">
                                Monthly income used for calculation
                              </IonLabel>
                              <IonText className={this.currentAccountOffer.avgMonthlyIncome90 ? "textBold" : ""}>
                                {getPriceString(this.currentAccountOffer.avgMonthlyIncome90) || "No income"}
                              </IonText>
                            </IonItem>
                            {/*<IonItem lines="full">*/}
                            {/*  <IonLabel position="stacked" className="textPrimary">*/}
                            {/*    Monthly income provided by stats*/}
                            {/*  </IonLabel>*/}
                            {/*  <IonText className={!!this.currentAccountOffer.threeMonthIncomeAvgStat ? "" : "textMedium textStrikeThrough"}>*/}
                            {/*    {getPriceString(this.currentAccountOffer.threeMonthIncomeAvgStat) || "-"}*/}
                            {/*  </IonText>*/}
                            {/*</IonItem>*/}
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textPrimary">
                                Monthly income calculated using transactions
                              </IonLabel>
                              <IonText
                                className={
                                  (this.currentAccountOffer.avgMonthlyIncomeUsedProperty === "threeMonthIncomeAvgCalc")
                                    ? ""
                                    : "textMedium textStrikeThrough"}
                              >
                                {getPriceString(this.currentAccountOffer.threeMonthIncomeAvgCalc) || "-"}
                              </IonText>
                            </IonItem>
                          </div>
                          <div className="flex half ion-align-items-end">
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textPrimary">
                                Monthly income inputted during application
                              </IonLabel>
                              <IonText
                                className={
                                  (this.currentAccountOffer.avgMonthlyIncomeUsedProperty === "inputMonthlyIncome")
                                    ? ""
                                    : "textMedium textStrikeThrough"}
                              >
                                {getPriceString(this.currentAccountOffer.inputMonthlyIncome) || "-"}
                              </IonText>
                            </IonItem>
                          </div>
                          <IonItemDivider className="dividerNoBorder">Housing (Rental/Mortgage)</IonItemDivider>
                          <div className="flex half ion-align-items-end">
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textSuccess">
                                Monthly housing payment used for calculation
                              </IonLabel>
                              <IonText className="textBold">
                                {getPriceString(this.currentAccountOffer.housingMonthly) || "-"}
                              </IonText>
                            </IonItem>
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textPrimary">
                                Monthly housing inputted during application
                              </IonLabel>
                              <IonText>
                                {getPriceString(this.currentAccountOffer.housingMonthlyInput) || "-"}
                              </IonText>
                            </IonItem>
                          </div>
                          <div className="flex half ion-align-items-end">
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textPrimary">
                                Monthly housing calculated using transaction
                              </IonLabel>
                              <IonText
                                className={
                                  (!this.currentAccountOffer.housingMonthlyInput &&
                                    !!this.currentAccountOffer.housing90avg)
                                    ? ""
                                    : "textMedium textStrikeThrough"}
                              >
                                {getPriceString(this.currentAccountOffer.housing90avg) || "-"}
                              </IonText>
                            </IonItem>
                          </div>
                        </>}
                      </IonList>
                    </IonCardContent>
                  </IonCard>
                </div>
              </Accordion>

              {this.renderAccountPeriodSection("thirtyDays", "30 Day Statistics")}
              {this.renderAccountPeriodSection("threeMonths", "90 Day Statistics")}
              {this.renderAccountPeriodSection("sixMonths", "180 Day Statistics")}
              {this.renderAccountPeriodSection("annually", "Annual Statistics")}

              <Accordion
                ref={this.incomeRef}
                duration={200}
                className="ion-padding accountSection incomes"
                backgroundColor="#ffffff00"
                headerComponent={
                  <AccountSectionHeader
                    title="Incomes (Bank statement raw data)"
                    isExpanded={this.accordionController.isAccordionExpanded("incomes")}
                  />
                }
                expanded={this.accordionController.isAccordionExpanded("incomes")}
                onClick={e => this.handleAccordionClick(e, "incomes")}
              >
                <div className="flex column">
                  {!isEmpty(this.currentAccount.incomes) ? this.currentAccount.incomes.map(income => (
                    <IonCard key={income.description}>
                      <IonCardContent>
                        <IonList className="ion-no-padding">
                          <IonItem lines="full">
                            <IonLabel position="stacked" className="textPrimary">
                              Description
                            </IonLabel>
                            <IonText>
                              {income.description}
                            </IonText>
                          </IonItem>
                          <div className="flex half ion-align-items-end">
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textPrimary">
                                Amount per month:
                              </IonLabel>
                              <IonText>
                                {getPriceString(income.amountPerMonth)}
                              </IonText>
                            </IonItem>
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textPrimary">
                                Transactions
                              </IonLabel>
                              <IonText>
                                {income.transactions}
                              </IonText>
                            </IonItem>
                          </div>
                          <div className="flex half ion-align-items-end">
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textPrimary">
                                [Inverite] Income type:
                              </IonLabel>
                              <IonText>
                                {income.incomeTypeRaw}
                              </IonText>
                            </IonItem>
                            <IonItem lines="full">
                              <IonLabel position="stacked" className="textPrimary">
                                [Inverite] Frequency type:
                              </IonLabel>
                              <IonText>
                                {income.frequencyTypeRaw}
                              </IonText>
                            </IonItem>
                          </div>
                          {this.renderTransactionTable(income.transactionDetails, e => this.incomeElm && this.incomeElm.ensureHeight(), true)}
                        </IonList>
                      </IonCardContent>
                    </IonCard>
                  )) : <IonText className="ion-margin-top ion-text-center">No income data</IonText>}
                </div>
              </Accordion>

              {/*<Accordion*/}
              {/*  ref={this.additionalIncomeRef}*/}
              {/*  duration={200}*/}
              {/*  className="ion-padding accountSection incomes"*/}
              {/*  backgroundColor="#ffffff00"*/}
              {/*  headerComponent={*/}
              {/*    <AccountSectionHeader*/}
              {/*      title="Additional Incomes From Transactions"*/}
              {/*      isExpanded={this.accordionController.isAccordionExpanded("additionalIncomes")}*/}
              {/*    />*/}
              {/*  }*/}
              {/*  expanded={this.accordionController.isAccordionExpanded("additionalIncomes")}*/}
              {/*  onClick={e => this.handleAccordionClick(e, "additionalIncomes")}*/}
              {/*>*/}
              {/*  <div className="flex column">*/}
              {/*    {!isEmpty(this.currentAccount.additionalIncomes) ? this.currentAccount.additionalIncomes.map(income => (*/}
              {/*      <IonCard key={income.description}>*/}
              {/*        <IonCardContent>*/}
              {/*          <IonList className="ion-no-padding">*/}
              {/*            <IonItem lines="full">*/}
              {/*              <IonLabel position="stacked" className="textPrimary">*/}
              {/*                Description*/}
              {/*              </IonLabel>*/}
              {/*              <IonText>*/}
              {/*                {income.description}*/}
              {/*              </IonText>*/}
              {/*            </IonItem>*/}
              {/*            <div className="flex half ion-align-items-end">*/}
              {/*              <IonItem lines="full">*/}
              {/*                <IonLabel position="stacked" className="textPrimary">*/}
              {/*                  Total amount:*/}
              {/*                </IonLabel>*/}
              {/*                <IonText>*/}
              {/*                  ${income.totalAmount}*/}
              {/*                </IonText>*/}
              {/*              </IonItem>*/}
              {/*              <IonItem lines="full">*/}
              {/*                <IonLabel position="stacked" className="textPrimary">*/}
              {/*                  Transactions*/}
              {/*                </IonLabel>*/}
              {/*                <IonText>*/}
              {/*                  {income.transactions}*/}
              {/*                </IonText>*/}
              {/*              </IonItem>*/}
              {/*            </div>*/}
              {/*            {this.renderTransactionTable(income.transactionDetails, e => this.additionalIncomeElm && this.additionalIncomeElm.ensureHeight(), true)}*/}
              {/*          </IonList>*/}
              {/*        </IonCardContent>*/}
              {/*      </IonCard>*/}
              {/*    )) : <IonText className="ion-margin-top ion-text-center">No additional income data</IonText>}*/}
              {/*  </div>*/}
              {/*</Accordion>*/}

              {/*<Accordion*/}
              {/*  ref={this.otherLoansRef}*/}
              {/*  duration={200}*/}
              {/*  className="ion-padding accountSection otherLoans"*/}
              {/*  backgroundColor="#ffffff00"*/}
              {/*  headerComponent={*/}
              {/*    <AccountSectionHeader*/}
              {/*      title="Other Loans"*/}
              {/*      isExpanded={this.accordionController.isAccordionExpanded("otherLoans")}*/}
              {/*    />*/}
              {/*  }*/}
              {/*  expanded={this.accordionController.isAccordionExpanded("otherLoans")}*/}
              {/*  onClick={e => this.handleAccordionClick(e, "otherLoans")}*/}
              {/*>*/}
              {/*  <div className="flex column">*/}
              {/*    {!isEmpty(this.currentAccount.otherLoans) ? this.currentAccount.otherLoans.map(loan => (*/}
              {/*      <IonCard key={loan.description}>*/}
              {/*        <IonCardContent>*/}
              {/*          <IonList className="ion-no-padding">*/}
              {/*            <IonItem lines="full">*/}
              {/*              <IonLabel position="stacked" className="textPrimary">*/}
              {/*                Description*/}
              {/*              </IonLabel>*/}
              {/*              <IonText>*/}
              {/*                {loan.description}*/}
              {/*              </IonText>*/}
              {/*            </IonItem>*/}
              {/*            <div className="flex half ion-align-items-end">*/}
              {/*              <IonItem lines="full">*/}
              {/*                <IonLabel position="stacked" className="textPrimary">*/}
              {/*                  Total amount*/}
              {/*                </IonLabel>*/}
              {/*                <IonText>*/}
              {/*                  ${loan.totalAmount}*/}
              {/*                </IonText>*/}
              {/*              </IonItem>*/}
              {/*              <IonItem lines="full">*/}
              {/*                <IonLabel position="stacked" className="textPrimary">*/}
              {/*                  Total transactions*/}
              {/*                </IonLabel>*/}
              {/*                <IonText>*/}
              {/*                  {loan.transactions}*/}
              {/*                </IonText>*/}
              {/*              </IonItem>*/}
              {/*            </div>*/}
              {/*            {this.renderTransactionTable(loan.transactionDetails, e => this.otherLoansElm && this.otherLoansElm.ensureHeight())}*/}
              {/*          </IonList>*/}
              {/*        </IonCardContent>*/}
              {/*      </IonCard>*/}
              {/*    )) : <IonText className="ion-margin-top ion-text-center">No other loans data</IonText>}*/}
              {/*  </div>*/}
              {/*</Accordion>*/}

              <Accordion
                duration={200}
                className="ion-padding accountSection returnedTransactions"
                backgroundColor="#ffffff00"
                headerComponent={
                  <AccountSectionHeader
                    title="Returned Transactions"
                    isExpanded={this.accordionController.isAccordionExpanded("returnedTransactions")}
                  />
                }
                expanded={this.accordionController.isAccordionExpanded("returnedTransactions")}
                onClick={e => this.handleAccordionClick(e, "returnedTransactions")}
              >
                <IonCard>
                  <IonCardContent>
                    <IonList className="ion-no-padding">
                      {this.renderTransactionTable(this.currentAccount.returnedTransactions, null, true, true)}
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </Accordion>

              <Accordion
                duration={200}
                className="ion-padding accountSection bankruptcyTransactions"
                backgroundColor="#ffffff00"
                headerComponent={
                  <AccountSectionHeader
                    title="Bankruptcy Transactions"
                    isExpanded={this.accordionController.isAccordionExpanded("bankruptcyTransactions")}
                  />
                }
                expanded={this.accordionController.isAccordionExpanded("bankruptcyTransactions")}
                onClick={e => this.handleAccordionClick(e, "bankruptcyTransactions")}
              >
                <IonCard>
                  <IonCardContent>
                    <IonList className="ion-no-padding">
                      {this.renderTransactionTable(this.currentAccount.bankruptcyTransactions, null, true, true)}
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </Accordion>

              <Accordion
                duration={200}
                className="ion-padding accountSection collectionTransactions"
                backgroundColor="#ffffff00"
                headerComponent={
                  <AccountSectionHeader
                    title="Collection Transactions"
                    isExpanded={this.accordionController.isAccordionExpanded("collectionTransactions")}
                  />
                }
                expanded={this.accordionController.isAccordionExpanded("collectionTransactions")}
                onClick={e => this.handleAccordionClick(e, "collectionTransactions")}
              >
                <IonCard>
                  <IonCardContent>
                    <IonList className="ion-no-padding">
                      {this.renderTransactionTable(this.currentAccount.collectionTransactions, null, true, true)}
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </Accordion>
            </div>

          </div>
        )}
      </IonContent>
    </IonPage>
  }
}

export default withIonLifeCycle(Verification);
