import Axios, { AxiosInstance, AxiosRequestConfig, Method } from "axios";
import { observable } from "mobx";
import { baseURL, serverConfig } from "../config/api/base";
import { env } from "../config/env";
import { ApiOptions } from "../lib/types/miscTypes";

const config: Partial<AxiosRequestConfig> = {
  baseURL,
  headers: serverConfig.defaultHeaders
};

export class Api {
  @observable pending: number = 0;
  axios: AxiosInstance;

  constructor() {
    this.axios = Axios.create(config);
  }

  async = async (method: Method, overload1: string | ApiOptions, overload2?: ApiOptions) => {
    const options = (typeof overload1 === "object" ? overload1 : overload2) || {} as ApiOptions;

    return this.axios({
      method: method.toLowerCase(),
      url: (typeof overload1 === "string" && overload1) || options.endpoint,
      data: options.data,
      auth: !options.noAuth && options.auth,
      responseType: options.responseType,
      noKickOut: options.noKickOut,
      headers: {
        ...options.headers
      }
    } as ApiOptions);
  };

  GET = options => this.async("GET", options);

  POST = options => this.async("POST", options);

  PATCH = options => this.async("PATCH", options);

  PUT = options => this.async("PUT", options);

  DELETE = options => this.async("DELETE", options);
}

export const api = new Api();

if (window && env as string !== "prod") (window as any).api = api;
