import { env } from "../env";

export const testServer = "dashboard-backend-ext-f2nhltqewq-uw.a.run.app";
export const prodServer = "dashboardapi.flexfi.ca/flexfi/ext";

const hostname = env === "test"
  ? testServer
  : env === "prod"
  ? prodServer
  : "localhost";
const port = env === "local" ? 5000 : 443;
const https = env !== "local" || window.location.protocol === "https:";
const pathname = "";

export const serverConfig = {
  hostname,
  port,
  https,
  pathname,

  defaultHeaders: {
    Authorization: `Basic ${btoa("dashboard:dashboard")}`
  },
};

export const serverPortString =
  serverConfig.port.toString().match(/^80$|^443$/g)
    ? ""
    : `:${serverConfig.port}`;

export const serverHostnameString = `${
  serverConfig.https ? "https://" : "http://"
  }${serverConfig.hostname}${serverPortString}${serverConfig.pathname}`;

export const baseURL = serverHostnameString;
