export const endpointConfig = {
  fetch_request_by_id: id => `/inverite/fetch/${id}`,
  fetch_request_by_id_to_bs_import: id =>`/inverite/fetch/${id}/toBsImport`,
  get_raw_requests: format => `/inverite/requests/raw?format=${format ? 1 : 0}`,
  get_raw_requests_with_range: "/inverite/requests/raw/ranged",
  get_raw_request_by_id: (id, format) => `/inverite/requests/raw/${id}?format=${format ? 1 : 0}`,
  get_raw_request_by_app_id: (appId, format) => `/inverite/requests/raw/appId/${appId}?format=${format ? 1 : 0}`,
  get_loan_application_user_by_app_id: id => `/user/loanApplication/${id}`,
  get_loan_application_by_interite_id: id => `/inverite/loanApplication/inveriteId/${id}`,
  get_loan_offer_by_inverite_id: id => `/inverite/offers/${id}`,
  save_raw_requests: "/inverite/fetch/save",
  google_places_search: address => `/inverite/places/search?address=${address}`,
  google_geocoding_search: address => `/inverite/geocoding/search?address=${address}`,

  login_user_pass: "/inverite/auth/userPass",
  verify_iframe_token: "/inverite/auth/token"
};
