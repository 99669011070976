import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { observer } from "mobx-react";
import { observable } from "mobx";
import { chevronDown, chevronUp } from "ionicons/icons";
import { capitalize, isEmpty } from "../../utils/helpers";
import { BsImportFormat } from '../../lib/types/dataTypes';
import { api } from "../../client/api";
import { endpointConfig } from "../../config/api";

@observer
class Index extends React.Component {
  @observable requestId: any;
  @observable jsonData: any;
  @observable bsImportData: BsImportFormat = {} as BsImportFormat;
  @observable expanded: boolean = false;
  @observable bsExpanded: boolean = false;
  @observable bsShowMode: string = "form";

  fetchData = async () => {
    if (!this.requestId) return;
    api.GET(endpointConfig.fetch_request_by_id(this.requestId))
    .then(response => this.jsonData = response.data)
    .then(() => api.GET(endpointConfig.fetch_request_by_id_to_bs_import(this.requestId)))
    .then(response => this.bsImportData = response.data);
  };

  renderArray = (arr: any[]): any =>
    (arr || []).map(item =>
      Array.isArray(item)
        ? this.renderArray(item)
        : typeof item === "string" || typeof item === "number"
        ? <IonInput key={item} readonly value={item} />
        : <div style={{ marginBottom: 20, border: "2px solid #aaa" }}>
          {this.renderProperties(item)}
        </div>
    );

  renderProperties = (obj: any): any => {
    if (typeof obj !== "object") return;
    if (Array.isArray(obj)) return this.renderArray(obj);
    return <>{
      Object.keys(obj).map(key => {
        return !isEmpty(obj[key]) && key !== "transactionDetails" && <IonItem key={key}>
          <IonLabel position="floating">
            {(key.match(/[A-Za-z][a-z]*/g) || []).map(capitalize).join(" ")}
          </IonLabel>
          {Array.isArray(obj[key])
            ? <div style={{ marginTop: 20, paddingLeft: 20, width: "100%" }}>{this.renderArray(obj[key])}</div>
            : typeof obj[key] === "object"
              ? <div style={{ marginTop: 20, paddingLeft: 20, width: "100%" }}>{this.renderProperties(obj[key])}</div>
              : <IonInput readonly value={obj[key]} />
          }
        </IonItem>
      }).filter(Boolean)
    }</>
  };

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Verification Fetch Tool</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div style={{ maxWidth: 1366, margin: "0 auto" }}>
            <IonList className="ion-padding">
              <IonItem lines="full" className="ion-margin-bottom">
                <IonLabel position="floating">
                  Request ID
                </IonLabel>
                <IonInput value={this.requestId} onIonChange={e => this.requestId = e.detail.value} />
                <IonButton slot="end" size="default" onClick={this.fetchData}>Fetch</IonButton>
              </IonItem>
              <IonItem lines="full" className="ion-margin-bottom">
                <style>{`textarea { min-height: ${this.expanded ? "80vh" : "5vh"} }`}</style>
                <IonLabel position="floating">
                  Raw JSON Data
                </IonLabel>
                <IonTextarea
                  readonly
                  style={{ whiteSpace: "pre-wrap" }}
                  value={typeof this.jsonData === "string" ? this.jsonData : JSON.stringify(this.jsonData, null, 2)}
                />
              </IonItem>
              <IonButtons className="ion-justify-content-center ion-align-items-center">
                <IonButton onClick={() => this.expanded = !this.expanded}>
                  <IonIcon icon={this.expanded ? chevronUp: chevronDown} slot="icon-only" color="primary" />
                </IonButton>
              </IonButtons>
            </IonList>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <IonList className="ion-padding">
                <IonListHeader>
                  <IonText color="primary">Bank Statement Import Formatted</IonText>
                </IonListHeader>
                <IonSegment value={this.bsShowMode} onIonChange={e => this.bsShowMode = e.detail.value as string}>
                  <IonSegmentButton value="form">
                    <IonLabel>Show Form</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="json">
                    <IonLabel>Show JSON</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
                {this.bsShowMode === "form"
                  ? this.renderProperties(this.bsImportData)
                  : (
                    <>
                      <IonItem lines="full" className="ion-margin-bottom">
                        <style>{`.bsJson > * > textarea { min-height: ${this.bsExpanded ? "80vh" : "5vh"} }`}</style>
                        <IonLabel position="floating">
                          Formatted JSON Data
                        </IonLabel>
                        <IonTextarea
                          className="bsJson"
                          readonly
                          style={{ whiteSpace: "pre-wrap" }}
                          value={JSON.stringify(this.bsImportData, null, 2)}
                        />
                      </IonItem>
                      <IonButtons className="ion-justify-content-center ion-align-items-center">
                        <IonButton onClick={() => this.bsExpanded = !this.bsExpanded}>
                          <IonIcon icon={this.bsExpanded ? chevronUp: chevronDown} slot="icon-only" color="primary" />
                        </IonButton>
                      </IonButtons>
                    </>
                  )}
              </IonList>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default Index;
