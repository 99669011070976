import { env } from "../config/env";
import { alertController, PopoverOptions } from "@ionic/core";
import { isEmpty, parseErrorMsg } from "../utils/helpers";
import { UIAlertOptions, UIErrorOptions, UIPopoverMenuOptions } from "../lib/types/miscTypes";
import { observable } from "mobx";
import { PopoverMenuBase } from "../components/PopoverMenuBase";
import { IReactComponent } from "mobx-react/dist/types/IReactComponent";


export class UI {
  @observable sidebarDisabled: boolean = false;

  @observable popoverProps: PopoverOptions;
  @observable popoverComponent: React.FC | IReactComponent;
  @observable popoverVisible: boolean = false;

  withTranslucent = {
    translucent: true
  };

  alert = (options: UIAlertOptions) => {
    if (isEmpty(options)) return;
    let popup;
    return alertController.create({ ...options, ...this.withTranslucent })
    .then(p => p.present() && (popup = p))
    .then(() => popup);
  };

  showError = (options: UIErrorOptions) => {
    const { err, actionName, buttons } = options;
    console.warn(err);
    return this.alert({
      header: actionName || "Error",
      message: parseErrorMsg(err, env !== "prod") || "",
      backdropDismiss: true,
      cssClass: "errorAlert",
      buttons: buttons || ["Ok"]
    });
  };

  popover = (options: Partial<PopoverOptions> & { component: UI["popoverComponent"], event: any }) => {
    if (isEmpty(options)) return;
    const { event } = options;
    if (event && event.persist) event.persist();
    if (event.button === 2) {
      const rect = { ...event.target.getBoundingClientRect() };
      const { clientX, clientY } = event;
      rect.bottom = clientY;
      rect.left = clientX;
      event.target.getBoundingClientRect = () => rect;
    }
    const { component } = options;
    if (!!options.component) delete options.component;
    this.popoverProps = { ...options, ...this.withTranslucent };
    this.popoverComponent = component;
    this.popoverVisible = true;
  };

  popoverMenu = (options: UIPopoverMenuOptions) => {
    const component = PopoverMenuBase(options.menuItems, this.dismissPopover);
    return this.popover({
      event: options.event,
      component,
      showBackdrop: options.showBackdrop
    });
  };

  dismissPopover = (event?: any) => {
    const popoverContainer = document.getElementsByClassName("global-popover-container")[0];
    if (!popoverContainer) return this.onDismissPopover();
    return (popoverContainer as HTMLIonPopoverElement).dismiss();
  };

  onDismissPopover = () => this.popoverVisible = false;
}

export const ui = new UI();

if (window && env as string !== "prod") (window as any).ui = ui;
