export const getQueryParameters = str => {
  return (str || "")
  .replace(/(^\?)/, "")
  .split("&")
  .map(
    function(n) {// eslint-disable-next-line
      return (n = n.split("=")), (this[n[0]] = n[1]), this;
    }.bind({})
  )[0];
};

export const capitalize = string => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isEmpty = obj => {
  if (!obj) return true;
  if (typeof obj === "string") return !(obj || "").trim();
  if (Array.isArray(obj)) return obj.length === 0;
  if (typeof obj === "object") {
    const keys = Object.keys(obj);
    if (keys.length === 0) {
      return obj.constructor === Object;
    } else {
      let empty = true;
      for (let key of keys) {
        if (!isEmpty(obj[key])) {
          empty = false;
          return empty;
        }
      }
      return empty;
    }
  }
};

export const getPriceString = (amount, symbol, comma) => {
  if (!amount || isNaN(amount)) return "";
  const neutral = amount >= 0 ? amount : -amount;
  let price = Number(neutral).toFixed(2);
  if (comma) price = price.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return amount >= 0 ? `${symbol || "$"}${price}` : `-${symbol || "$"}${price}`;
};

export const parseErrorMsg = (err, includeStack) => {
  if (!err || isEmpty(err)) return err;
  if (typeof err === "string" || typeof err === "number") {
    return err;
  }
  if (typeof err === "object") {
    const responseData = err.response && err.response.data;
    if (responseData) {
      if (typeof responseData === "string") {
        return responseData.concat(includeStack && err.stack ? `\n \n${err.stack}` : "");
      }
      if ((responseData.error || responseData.name) &&
        responseData.message &&
        (responseData.trace || responseData.stack)) {
        if (includeStack) {
          return responseData.message.concat("\n \n" + responseData.trace || responseData.stack);
        }
        return responseData.message;
      }
      try {
        const errorStr = JSON.stringify(responseData, null, 2);
        if (includeStack && err.stack) return errorStr.concat("\n \n" + err.stack);
        return errorStr;
      } catch (e) {
        return e;
      }
    }
    return (err.message || "No message available")
    .concat(includeStack && err.stack ? `\n \n${err.stack}` : "");
  }
  return err;
};

export const getDisplayName = input => {
  if (isEmpty(input)) return "";
  return (
    (input.displayName && input.displayName.trim()) ||
    ((input.first_name || input.last_name) && `${(input.first_name || "").trim()} ${(input.last_name || "").trim()}`) || ""
  ).trim();
};
