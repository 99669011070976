import { observer } from "mobx-react";
import { IonItem, IonText } from "@ionic/react";
import * as React from "react";
import { BsImportFormat } from "../../lib/types/dataTypes";
import "./styles.css";
import { inverite } from "../../client/inverite";

export interface InveriteItemProps {
  item: BsImportFormat;
}

const InveriteItem: React.FC<InveriteItemProps> = observer(
  ({ item }) => (
    <IonItem
      lines="full"
      className="inveriteItem"
      routerLink={`/${item.inveriteId}`}
    >
      <div className="flex column">
        <div className="flex ion-justify-content-between">
          <IonText className={`font-s ${!item.clientName ? "textItalic textMedium" : ""}`}>{item.clientName || "Unknown client name"}</IonText>
          <IonText color="medium" className="font-xs">App ID:&nbsp;{inverite.referenceToAppId(item.referenceId)}</IonText>
        </div>
        <IonText color="medium" className="font-xs">Created:&nbsp;{item.date}</IonText>
        <IonText color="medium" className="font-xs">{item.inveriteId}</IonText>
      </div>
    </IonItem>
));

export default InveriteItem;
