import { env } from "../config/env";
import { api } from "./api";
import { endpointConfig } from "../config/api";
import { AxiosResponse } from "axios";
import { ui } from "./ui";
import { StdErr } from "../lib/types/miscTypes";
import { IObservableArray, observable } from "mobx";
import { BsImportFormat, BsImportFormatAccount, BsImportFormatAccountOffer, DbUser } from "../lib/types/dataTypes";
import { isEmpty } from "../utils/helpers";

export class Inverite {
  @observable passwordEntered: boolean = false;

  @observable verifications: IObservableArray<BsImportFormat> = [] as IObservableArray<BsImportFormat>;
  @observable searchValue: string = "";
  @observable viewSelectedAccounts = {};

  showError = (err: StdErr) => ui.showError({ err, actionName: "Get Imported Verifications" })

  loadAllData = async () => {
    this.verifications.clear();
    return Promise.all([
      this.getMoreData(),
      // this.getAllData()
    ]);
  }

  getAllData = async () => api.GET(endpointConfig.get_raw_requests(true))
  .then((response: AxiosResponse<BsImportFormat[]>) => {
    if (!response) throw new Error("Network error. Cannot get imported verifications.");
    const data = response.data || [];
    return this.verifications.replace(this.sortData(data));
  })
  .catch(this.showError)

  getMoreData = async () => {
    const lastDate = (this.verifications[this.verifications.length - 1] || {}).date;
    return api.POST({
      endpoint: endpointConfig.get_raw_requests_with_range,
      data: {
        format: true,
        from: lastDate,
        count: 10
      }
    })
    .then((response: AxiosResponse<BsImportFormat[]>) => {
      if (!response) throw new Error("Network error. Cannot get imported verifications.");
      const data = response.data || [];
      const newData = data.filter(d => !this.verifications.some(v => v.inveriteId === d.inveriteId));
      return this.verifications.push(...this.sortData(newData));
    })
    .catch(this.showError)
  }

  sortData = (data: BsImportFormat[]) => (data || []).sort((a: BsImportFormat, b: BsImportFormat) => (
    -((a.date ? new Date(a.date).getTime() : 0) -
      (b.date ? new Date(b.date).getTime() : 0))
  ))

  findByAppId = async (appId: string | number, store?: boolean) => {
    return api.GET(endpointConfig.get_raw_request_by_app_id(appId, true))
    .then((response: AxiosResponse<BsImportFormat>) => {
      const verification = response.data;
      if (store) {
        const existing = this.verifications.find(v => v.inveriteId === verification.inveriteId);
        if (existing) {
          Object.assign(existing, verification);
        } else {
          this.verifications.push(verification);
        }
      }
      return verification;
    });
  }

  refreshVerification = (id: string) => api.POST({
    endpoint: endpointConfig.save_raw_requests,
    data: { id, override: true }
  })
  .then(response => this.updateVerificationById(id))

  updateVerificationById = (id: string) => api.GET(endpointConfig.get_raw_request_by_id(id, true))
  .then((response: AxiosResponse<BsImportFormat >) => {
    if (!response) throw new Error("Network error. Cannot get imported verifications.");
    const data = response.data;
    if (!data) return;
    const index = this.verifications.findIndex(v => v.inveriteId === data.inveriteId);
    if (index >= 0) this.verifications[index] = data;
  })

  getLoanApplicationByInveriteId = async (inveriteId: string) => api.GET(endpointConfig.get_loan_application_by_interite_id(inveriteId))
  .then(response => response && response.data);

  getLoanApplicationUserByAppId = async (appId: number) => api.GET(endpointConfig.get_loan_application_user_by_app_id(appId))
  .then((response: AxiosResponse<DbUser>) => response && response.data);

  getLoanOffersByInveriteId = async (inveriteId: string) => api.GET(endpointConfig.get_loan_offer_by_inverite_id(inveriteId))
  .then((response: AxiosResponse<BsImportFormatAccountOffer[]>) => response && response.data);

  handleSearchChange = (event: any) => {
    const value = event.target.value || event.detail.value;
    return this.searchValue = value;
  }

  referenceToAppId(reference: string): number {
    const appId = (reference || "").replace("finjoyca_appId_", "");
    return !isNaN(Number(appId)) ? Number(appId) : null;
  }

  verifyIframeToken = (token: string): Promise<boolean> => api.POST({
    endpoint: endpointConfig.verify_iframe_token,
    data: { token }
  })
  .then(response => {
    const pass = !!response.data;
    if (pass) return this.passwordEntered = true;
    return Promise.reject();
  })
  .catch(err => {
    console.error(err);
    return Promise.reject();
  });

  verifyPassword = (username: string, password: string) => api.POST({
    endpoint: endpointConfig.login_user_pass,
    data: { username, password }
  })
  .then(response => {
    const pass = !!response.data;
    if (pass) this.passwordEntered = true;
    return pass;
  })
  .catch(err => {
    console.error(err);
    return Promise.resolve(false);
  });

  getAccountFlags = (account: BsImportFormatAccount) => ({
    bankruptcyTx: !isEmpty(account.bankruptcyTransactions),
    returnedTx: !isEmpty(account.returnedTransactions),
    gamblingTx: !isEmpty(account.gamblingTransactions),
    collectionTx: account.hasCollection,
    finjoyTx: account.hasFinjoyTransaction
  });
}

export const inverite = new Inverite();

if (window && env as string !== "prod") (window as any).inverite = inverite;
