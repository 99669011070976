import React from "react";
import { IonSpinner } from "@ionic/react";
import { observer } from "mobx-react";
import "./styles.css";
import { PredefinedColors } from "@ionic/core";

export interface FullScreenSpinnerProps extends React.ComponentProps<typeof IonSpinner> {
  size: number;
  color: PredefinedColors;
}

const FullScreenSpinner: React.FC<FullScreenSpinnerProps> =
  observer(props =>
    <div className="flex max fullScreenSpinner">
      <IonSpinner
        style={props.size && { width: props.size, height: props.size }}
        {...props}
      />
    </div>
  );

export default FullScreenSpinner;